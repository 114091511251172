<template>
    <div class="container p-4 text-muted">
        <div v-if="!user" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner> Loading...
        </div>
        <div v-else>
            <div class="row">
                <div class="col col-md-6">
                    <ValidationObserver
                        @submit.prevent="onSubmit"
                        v-slot="{ invalid }"
                    >
                        <b-form novalidate>
                            <ValidationProvider
                                name="Name"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <b-form-group
                                    label="Name"
                                    label-for="name"
                                    description=""
                                >
                                    <b-form-input v-model="user.name" id="name">
                                    </b-form-input>
                                    <div class="error">
                                        <span class="small text-danger">{{
                                            errors[0]
                                        }}</span>
                                    </div>
                                </b-form-group>
                            </ValidationProvider>

                            <ValidationProvider
                                name="Password"
                                rules="min:6"
                                v-slot="{ errors }"
                            >
                                <b-form-group
                                    label="Password"
                                    label-for="password"
                                    description=""
                                >
                                    <b-form-input
                                        v-model="user.password"
                                        id="password"
                                        type="password"
                                    ></b-form-input>
                                    <div class="error">
                                        <span class="small text-danger">{{
                                            errors[0]
                                        }}</span>
                                    </div>
                                </b-form-group>
                            </ValidationProvider>

                            <b-form-group
                                label="Email"
                                label-for="email"
                                description=""
                            >
                                <b-form-input
                                    v-model="user.email"
                                    id="email"
                                    readonly
                                >
                                </b-form-input>
                            </b-form-group>

                            <b-form-group
                                label="Roles"
                                label-for="roles"
                                description=""
                            >
                                <div id="roles">
                                    <span
                                        v-for="role in user.roles"
                                        class="badge badge-info mr-2"
                                    >
                                        {{ role }}
                                    </span>
                                </div>
                            </b-form-group>

                            <b-form-group>
                                <b-form-checkbox
                                    v-model="user.keep"
                                    id="keepAccount"
                                >
                                    Do not delete this account during the
                                    biweekly rest</b-form-checkbox
                                >
                            </b-form-group>

                            <b-button
                                class="float-right"
                                variant="success"
                                type="submit"
                                :disabled="invalid"
                            >
                                <span class="mx-3">Save</span>
                            </b-button>
                        </b-form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '@/services/api.service'

export default {
    data() {
        return {
            user: null,
        }
    },
    methods: {
        loadUser() {
            ApiService.getUser().then(
                (response) => {
                    this.user = response.data.data
                },
                (error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    })
                }
            )
        },
        onSubmit() {
            ApiService.updateUser({
                name: this.user.name,
                password: this.user.password || null,
                keep: this.user.keep || false,
            }).then(
                (response) => {
                    this.$bvToast.toast(response.data.message, {
                        title: 'Information',
                        toaster: 'b-toaster-top-center',
                        variant: 'success',
                        solid: true,
                    })
                },
                (error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    })
                }
            )
        },
    },
    mounted() {
        this.loadUser()
    },
}
</script>
<style>
.error {
    height: 1rem;
}
</style>
